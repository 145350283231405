const Logo = () => {
	return (
		<div>
			<svg
				className="fill-white"
				version="1.1"
				id="Layer_1"
				xmlns="http://www.w3.org/2000/svg"
				xmlnsXlink="http://www.w3.org/1999/xlink"
				x="0px"
				y="0px"
				viewBox="0 0 1024 351.5"
				xmlSpace="preserve"
			>
				<g>
					<path
						d="M73.7,18v71.7H55.2V39H39.9v192.9h15.3v-50.7h18.5v72.1H39.9c-2.1,0-4.3-0.7-6.5-2.2c-2.2-1.5-4.3-3.4-6.1-5.7
		c-1.8-2.3-3.3-4.9-4.5-7.7c-1.2-2.8-1.8-5.3-1.8-7.7V43.5c0-2.8,0.6-5.6,1.9-8.6c1.3-3,2.9-5.7,4.8-8.3s4-4.6,6.4-6.2
		c2.3-1.6,4.7-2.4,7-2.4H73.7z"
					/>
					<path d="M155,253.3h-18.8V152.2L111.9,18h18.5l15.3,89.9l15-89.9h18.5L155,152.2V253.3z" />
					<path
						d="M217.8,18h32.5c2.1,0,4.4,0.8,6.9,2.4c2.4,1.6,4.6,3.7,6.5,6.2s3.5,5.3,4.8,8.3c1.3,3,1.9,5.8,1.9,8.6v78.8
		c0,2.1-0.6,4.4-1.8,6.7c-1.2,2.3-2.5,4.6-4,6.7c1.5,2.1,2.8,4.4,4,6.9c1.2,2.4,1.8,4.6,1.8,6.5v78.8c0,2.8-0.6,5.6-1.9,8.6
		c-1.3,3-2.9,5.7-4.8,8.3c-1.9,2.6-4.1,4.6-6.5,6.2c-2.4,1.6-4.7,2.4-6.9,2.4h-32.5V18z M236.6,124.8h15.3V39h-15.3V124.8z
		 M236.6,232.3h15.3v-86.1h-15.3V232.3z"
					/>
					<path
						d="M365.1,18v71.4h-18.5V39h-15.3v85.8h15.3v21.4h-15.3v85.8h15.3v-50.7h18.5v72.1h-33.8c-2.1,0-4.3-0.7-6.5-2.2
		c-2.2-1.5-4.3-3.4-6.1-5.7c-1.8-2.3-3.3-4.9-4.5-7.7c-1.2-2.8-1.8-5.3-1.8-7.7V43.5c0-2.8,0.6-5.6,1.9-8.6c1.3-3,2.9-5.7,4.8-8.3
		s4-4.6,6.4-6.2c2.3-1.6,4.7-2.4,7-2.4H365.1z"
					/>
					<path
						d="M407.2,18h32.5c2.1,0,4.4,0.8,6.9,2.4c2.4,1.6,4.6,3.7,6.5,6.2s3.5,5.3,4.8,8.3c1.3,3,1.9,5.8,1.9,8.6v79.4
		c0,2.1-0.5,4.4-1.4,6.9c-1,2.4-2.2,4.7-3.7,6.9c-1.5,2.1-3.1,4-4.9,5.7c-1.8,1.7-3.7,2.9-5.6,3.5l15.9,107.4h-18.5l-15.6-103V150
		v103.3h-18.8V18z M426,124.8h15.3V39H426V124.8z"
					/>
					<path d="M587.9,39h-17.5V18h53.9v21h-17.5v214.3h-18.8V39z" />
					<path
						d="M716.4,18v71.4h-18.5V39h-15.3v85.8h15.3v21.4h-15.3v85.8h15.3v-50.7h18.5v72.1h-33.8c-2.1,0-4.3-0.7-6.5-2.2
		c-2.2-1.5-4.3-3.4-6.1-5.7c-1.8-2.3-3.3-4.9-4.5-7.7c-1.2-2.8-1.8-5.3-1.8-7.7V43.5c0-2.8,0.6-5.6,1.9-8.6c1.3-3,2.9-5.7,4.8-8.3
		s4-4.6,6.4-6.2c2.3-1.6,4.7-2.4,7-2.4H716.4z"
					/>
					<path
						d="M792.6,253.3V39h-15.3v214.3h-18.8V18H791c2.1,0,4.4,0.8,6.9,2.4c2.4,1.6,4.6,3.7,6.5,6.2s3.5,5.3,4.8,8.3
		c1.3,3,1.9,5.8,1.9,8.6v209.8H792.6z"
					/>
					<path
						d="M887.3,146.2h-5.1v-21.4h23.6v128.5H872c-2.1,0-4.3-0.7-6.5-2.2c-2.2-1.5-4.3-3.4-6.1-5.7c-1.8-2.3-3.3-4.9-4.5-7.7
		c-1.2-2.8-1.8-5.3-1.8-7.7V43.5c0-2.8,0.6-5.6,1.9-8.6c1.3-3,2.9-5.7,4.8-8.3s4-4.6,6.4-6.2c2.3-1.6,4.7-2.4,7-2.4h32.5v72.1h-18.5
		V39H872v192.9h15.3V146.2z"
					/>
					<path
						d="M1000.5,253.3h-33.8c-2.1,0-4.3-0.7-6.5-2.2c-2.2-1.5-4.3-3.4-6.1-5.7c-1.8-2.3-3.3-4.9-4.5-7.7c-1.2-2.8-1.8-5.3-1.8-7.7
		V18h18.8v213.9H982V18h18.5V253.3z"
					/>
				</g>
				<g>
					<path
						d="M832.3,323.7c-2.5,0-4.7-0.3-6.8-0.8c-2.1-0.5-3.8-1.2-5.1-2l0.7-4.7c3.7,2,7.3,2.9,10.8,2.9c5.4,0,8.1-1.9,8.3-5.7
		c0-1.6-0.6-2.9-1.7-3.8c-1.1-0.9-3.4-2-6.7-3.1l-2.7-1c-3.2-1.1-5.4-2.4-6.8-3.9c-1.4-1.6-2-3.5-2.1-5.8c0-2.8,1.1-5.2,3.4-7.1
		c2.3-1.9,5.4-2.8,9.4-2.8c4.1,0,7.8,0.8,11.2,2.4l-1.6,4.2c-3.4-1.4-6.6-2.1-9.6-2.1c-2.3,0-4.1,0.5-5.4,1.4c-1.3,0.9-2,2.2-2,3.8
		c0,1.4,0.5,2.5,1.4,3.2c0.9,0.7,2.8,1.6,5.7,2.6c0.7,0.3,1.6,0.6,2.7,0.9c3.7,1.2,6.2,2.6,7.8,4.2c1.5,1.6,2.3,3.8,2.3,6.6
		c0,3.3-1.2,5.9-3.6,7.8C839.5,322.8,836.3,323.7,832.3,323.7z"
					/>
					<path
						d="M864.5,323.6c-3.6,0-6.1-0.9-7.6-2.6c-1.5-1.7-2.3-4.5-2.4-8.4v-21.8h-5.4l0.2-3.6l5.1-0.5l1.9-8.3l3.6-0.1v8.4h10.1v4.1
		H860v20.8c0,2.8,0.4,4.7,1.3,5.8c0.8,1.1,2.3,1.7,4.3,1.7c0.7,0,2.3-0.2,4.7-0.5l0.2,4.4C867.9,323.4,865.9,323.6,864.5,323.6z"
					/>
					<path
						d="M875.1,310.9v-24.1h5.5V311c0,1.6,0.2,2.9,0.7,4s1.1,2,1.9,2.6c0.8,0.6,1.8,1,2.9,1.3c1.1,0.3,2.3,0.4,3.7,0.4
		c2.9,0,5.1-0.6,6.7-1.9c1.6-1.3,2.4-3.4,2.4-6.4v-24.2h5.5v24.1c0,1.8-0.3,3.5-0.8,5s-1.2,2.7-2.1,3.7c-0.9,1-2,1.8-3.2,2.4
		s-2.6,1.1-4,1.4c-1.4,0.3-2.9,0.4-4.5,0.4c-1.6,0-3.1-0.1-4.5-0.4c-1.4-0.3-2.7-0.8-4-1.4c-1.3-0.6-2.4-1.5-3.3-2.4
		c-0.9-1-1.6-2.2-2.2-3.7C875.4,314.4,875.1,312.7,875.1,310.9z"
					/>
					<path
						d="M937.7,317.7c-1.2,1.8-2.8,3.3-4.8,4.4s-4.2,1.6-6.6,1.6c-4.8,0-8.5-1.7-11.2-5.1c-2.7-3.4-4.1-8-4.1-13.9
		c0-5.6,1.4-10.1,4.1-13.6c2.8-3.4,6.4-5.2,11.1-5.2c2.4,0,4.7,0.5,6.8,1.6c2.1,1,3.7,2.4,4.8,4.2l-0.1-3.1v-15.9l5.4-0.3V323h-4.7
		L937.7,317.7z M926.8,319c3.6,0,6.3-1.2,8.1-3.5c1.8-2.3,2.7-5.7,2.7-10c0-4.9-0.9-8.6-2.6-11c-1.7-2.5-4.5-3.7-8.3-3.7
		c-1.7,0-3.2,0.4-4.5,1.1c-1.3,0.7-2.3,1.7-3.1,3s-1.4,2.8-1.8,4.5c-0.4,1.7-0.6,3.5-0.6,5.5c0,2.8,0.3,5.3,1,7.3s1.8,3.7,3.4,5
		C922.7,318.4,924.6,319,926.8,319z"
					/>
					<path d="M959.2,273.6v6.5h-5.5v-6.5H959.2z M959.2,286.7V323h-5.5v-36.2H959.2z" />
					<path
						d="M1001.4,304.8c0,5.6-1.5,10.2-4.5,13.7s-7.1,5.2-12.4,5.2c-5.1,0-9.1-1.8-12.1-5.3c-3-3.5-4.5-8-4.5-13.6
		c0-5.6,1.5-10.2,4.5-13.7c3-3.5,7.2-5.2,12.4-5.2c5.2,0,9.2,1.7,12.2,5.2C1000,294.7,1001.4,299.3,1001.4,304.8z M973.6,304.9
		c0,4.3,1,7.7,2.9,10.3c1.9,2.6,4.7,3.9,8.3,3.9c3.6,0,6.3-1.3,8.2-3.9c1.8-2.6,2.8-6,2.8-10.4c0-4.4-0.9-7.9-2.7-10.5
		s-4.6-3.8-8.3-3.8c-3.6,0-6.4,1.3-8.3,3.9S973.6,300.6,973.6,304.9z"
					/>
				</g>
			</svg>
		</div>
	)
}

export default Logo
