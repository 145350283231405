import Logo from "./Logo"

const Header = () => {
	return (
		<div className="h-[500px] flex justify-center items-center bg-[#383a3c]">
			<div className="w-4/5 md:w-2/4 lg:w-1/4">
				<Logo />
			</div>
		</div>
	)
}

export default Header
