
import Header from "./compontents/Header"
import Body from "./compontents/Body"


const App = () => {
	return (
		<div className="App">		
			<Header />
			<Body />
		</div>
	)
}

export default App
