const Body = () => {
	return (
		<div>
			<div className="mt-10 h-[50px] flex justify-center items-center text-2xl">
				<a rel="noreferrer" target="_blank" href="https://store.steampowered.com/app/2242430/Zombies_Arent_Your_Friends/">
					Wishlist on Steam <i class="fa-brands fa-steam"></i>
				</a>
			</div>
			<div className="mt-3 h-[50px] flex justify-center items-center text-4xl">
				<a rel="noreferrer" target="_blank" className="block p-4" href="https://twitter.com/cybertengu">
					<i class="fa-brands fa-twitter"></i>
				</a>
				<a rel="noreferrer" target="_blank" className="block p-4" href="https://www.instagram.com/cybertengustudio/">
					<i class="fa-brands fa-instagram"></i>
				</a>
				<a rel="noreferrer" target="_blank" className="block p-4" href="https://www.tiktok.com/@cybertengustudio">
					<i class="fa-brands fa-tiktok"></i>
				</a>
			</div>
		</div>
	)
}

export default Body
